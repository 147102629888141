@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "OneShinhanBold";
  src: url("./assets/fonts/OneShinhanBold.woff") format("woff");
}
@font-face {
  font-family: "OneShinhanMedium";
  src: url("./assets/fonts/OneShinhanMedium.woff") format("woff");
}
@font-face {
  font-family: "OneShinhanLight";
  src: url("./assets/fonts/OneShinhanLight.woff") format("woff");
}

:root {
  font-family: "OneShinhanLight";
}

*::-webkit-scrollbar {
  width: 0.3rem;
}

*::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 1rem;
  cursor: pointer;
}
